import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import axios from "axios";

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = async () => {
    setErrorMessage("");

    if (!oldPassword.trim()) {
      setErrorMessage("请填写旧密码");
      return;
    }
    if (!newPassword.trim()) {
      setErrorMessage("请填写新密码");
      return;
    }
    if (!confirmPassword.trim()) {
      setErrorMessage("请填写确认新密码");
      return;
    }
    if (newPassword.trim() != confirmPassword.trim()) {
      setErrorMessage("新密码与确认新密码不一致");
      return;
    }

    const token = localStorage.getItem("admin-token");

    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    try {
      const response = await axios.put(
        API_ENDPOINT + "/admin/change_password",
        body,
        {
          headers: {
            "x-access-admin-token": token,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        if (response.data.success) {
          alert("修改密码成功");
          onClose();
        }
      }
      if (response.status === 500) {
        localStorage.removeItem("admin-token");
        navigate("/login");
      } else {
        setErrorMessage("修改密码失败");
      }
    } catch (error: any) {
      setErrorMessage("修改密码失败:" + error.response.data.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="gray.600">修改密码</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel color="gray.600">旧密码</FormLabel>
            <Input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">新密码</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">确认新密码</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>
          {errorMessage && (
            <Text color="red" fontSize="12px" textAlign="left" mt="20px">
              {errorMessage}
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            保存
          </Button>
          <Button variant="ghost" onClick={onClose}>
            取消
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
