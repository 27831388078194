import React, { useState, useEffect, Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Text,
  Link,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { ChangePasswordModal, MenusTable } from "components";
import axios from "axios";

export interface User {
  user_id: number;
  username: string;
  email: string;
  phone: string;
  organization_name: string;
}

export const Menus: React.FC = () => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const navigate = useNavigate();

  const [adminInfo, setAdminInfo] = useState<any>(null);
  const { user_id } = useParams<{ user_id: string }>();
  const [user, setUser] = useState<User | null>(null);

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          API_ENDPOINT + `/admin/users/${user_id}`,
          {
            headers: {
              "x-access-admin-token": localStorage.getItem("admin-token"),
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [user_id]);

  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    navigate("/login");
  };

  const handleBackClick = () => {
    navigate("/users");
  };

  useEffect(() => {
    const token = localStorage.getItem("admin-token");
    if (token) {
      axios
        .get(API_ENDPOINT + "/admin/profile", {
          headers: {
            "x-access-admin-token": token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAdminInfo(response.data);
          }
        })
        .catch((error) => {
          handleLogout();
        });
    }
  }, [navigate]);

  return (
    <Flex flexDirection="column" height="100vh">
      <Flex
        backgroundColor="blue.500"
        justifyContent="space-between"
        alignItems="center"
        padding={4}
        height="80px"
        width="100%"
        zIndex="1000"
      >
        <Text color="white" fontSize="xl">
          益语智库管理系统
        </Text>
        <Flex justifyContent="flex-end" alignItems="center" mr="30px">
          <HStack spacing={4} minW="250px">
            <Menu>
              <>
                <MenuButton
                  as={Button}
                  textAlign="left"
                  bg="white"
                  color="gray.500"
                  p={"12px 18px"}
                  w="100%"
                  h="60px"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box>
                      <Text>Hello,</Text>
                      <Text>{adminInfo?.admin_username}</Text>
                    </Box>
                    <TriangleDownIcon mr="30px" ml="10px" color="gray.500" />
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => setChangePasswordModalOpen(true)}
                    fontSize="16px"
                    color="gray.600"
                  >
                    修改密码
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    fontSize="16px"
                    color="gray.600"
                  >
                    退出登录
                  </MenuItem>
                </MenuList>
              </>
            </Menu>
            <Avatar size="lg" m="-45px" />
          </HStack>
        </Flex>
      </Flex>
      <Flex flex="1">
        <VStack
          spacing={4}
          align="stretch"
          backgroundColor="gray.200"
          padding={4}
          height="100%"
          w="150px"
        >
          <Link href="/users">用户管理</Link>
        </VStack>
        <Box padding={4} w="100%">
          <Button onClick={handleBackClick} mb="20px">
            返回
          </Button>
          <MenusTable user={user} />
        </Box>
        <ChangePasswordModal
          isOpen={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
        />
      </Flex>
    </Flex>
  );
};
