import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Text,
} from "@chakra-ui/react";
import { EmailIcon, UnlockIcon } from "@chakra-ui/icons";
import axios from "axios";

export const Login: React.FC = () => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // 获取token
    const token = localStorage.getItem("admin-token");

    // 如果token存在，验证其合法性
    if (token) {
      axios
        .get(API_ENDPOINT + "/admin/profile", {
          headers: {
            "x-access-token": token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate("/users");
          }
        })
        .catch((error) => {
          localStorage.removeItem("admin-token");
        });
    }
  }, [navigate]);

  const handleLogin = async () => {
    setErrorMessage("");

    if (!username.trim()) {
      setErrorMessage("请填写账号");
      return;
    }
    if (!password.trim()) {
      setErrorMessage("请填写密码");
      return;
    }

    const body = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(API_ENDPOINT + "/admin/login", body);

      if (response.status === 200) {
        console.log(response.data.token);
        if (response.data.auth) {
          localStorage.setItem("admin-token", response.data.token);
          navigate("/users");
        }
      } else {
        alert("登录失败");
      }
    } catch (error: any) {
      setErrorMessage("登录失败:" + error.response.data.message);
    }
  };
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      bg="#124991"
      w="100vw"
      h="100vh"
    >
      <Box
        w="1200px"
        bg="white"
        borderRadius="36px"
        textAlign="center"
        fontSize="xl"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          h="100%"
          p="36px 50px"
        >
          <Box>
            <Image src="/images/login-logo.png" alt="logo" w="180px" />
            <Image src="/images/login-main.png" alt="logo" w="550px" />
          </Box>
          <Box mr="20px">
            <Tabs variant="unstyled">
              <TabList m="0px 36px">
                <Tab
                  ml="0px"
                  fontSize="36px"
                  fontWeight="bold"
                  color="gray.600"
                  _selected={{
                    color: "#0097e1",
                  }}
                >
                  益语智库管理系统
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel h="400px" mt="100px">
                  <Box>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <EmailIcon color="#0097e1" />
                      </InputLeftElement>
                      <Input
                        type="text"
                        placeholder="请输入您的账号"
                        bg="#f0f8fd"
                        borderRadius="36px"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup mt="42px">
                      <InputLeftElement pointerEvents="none">
                        <UnlockIcon color="#0097e1" />
                      </InputLeftElement>
                      <Input
                        type="password"
                        placeholder="请输入登录密码"
                        bg="#f0f8fd"
                        borderRadius="36px"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                    {errorMessage && (
                      <Text
                        color="red"
                        fontSize="12px"
                        textAlign="left"
                        mt="20px"
                      >
                        {errorMessage}
                      </Text>
                    )}
                    <Button
                      colorScheme="blue"
                      w="100%"
                      borderRadius="36px"
                      mt="100px"
                      p="24px"
                      onClick={handleLogin}
                    >
                      登录
                    </Button>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
