import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

interface AddMenuModalProps {
  userId: number | undefined;
  isOpen: boolean;
  onClose: () => void;
  onMenuAdded: () => void; // Callback to refresh the menu list after adding
}

export const AddMenusModal: React.FC<AddMenuModalProps> = ({
  userId,
  isOpen,
  onClose,
  onMenuAdded,
}) => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const toast = useToast();
  const [menuName, setMenuName] = useState("");
  const [link, setLink] = useState("");
  const [sort, setSort] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const resetForm = () => {
    setMenuName("");
    setLink("");
    setSort("");
    setErrorMessage(null);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleAdd = async () => {
    setErrorMessage("");
    if (!menuName || !link || !sort) {
      setErrorMessage("菜单名、链接和排序都是必填项。");
      return;
    }

    const body = {
      user_id: userId,
      menu_name: menuName,
      link,
      sort: parseInt(sort, 10),
    };

    try {
      const response = await axios.post(API_ENDPOINT + `/admin/menus`, body, {
        headers: {
          "x-access-admin-token": localStorage.getItem("admin-token"),
        },
      });

      if (response.status === 200 || response.status === 201) {
        onMenuAdded();
        onClose();
        toast({
          title: "新增成功",
          description: "菜单新增成功",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "新增失败",
          description: "菜单新增失败",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "新增失败",
        description: "菜单新增失败",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="gray.600">新增菜单</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel color="gray.600">菜单名</FormLabel>
            <Input
              value={menuName}
              onChange={(e) => setMenuName(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">链接</FormLabel>
            <Input value={link} onChange={(e) => setLink(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">排序</FormLabel>
            <Input
              type="number"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {errorMessage && (
            <Box color="red.500" mb={4}>
              {errorMessage}
            </Box>
          )}
          <Button colorScheme="blue" mr={3} onClick={handleAdd}>
            添加
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            取消
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
