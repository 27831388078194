import React, { useState, useEffect, Component } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Text,
  Link,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { ChangePasswordModal, UserTable } from "components";

import axios from "axios";

export const Users: React.FC = () => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const navigate = useNavigate();

  const [adminInfo, setAdminInfo] = useState<any>(null);
  const [userList, setUserList] = useState([]);

  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false);

  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    navigate("/login");
  };

  const getUserList = () => {
    const token = localStorage.getItem("admin-token");
    if (token) {
      axios
        .get(API_ENDPOINT + "/admin/users?limit=100", {
          headers: {
            "x-access-admin-token": token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setUserList(response.data);
          }
        })
        .catch((error) => {
          handleLogout();
        });
    } else {
      handleLogout();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("admin-token");
    if (token) {
      axios
        .get(API_ENDPOINT + "/admin/profile", {
          headers: {
            "x-access-admin-token": token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAdminInfo(response.data);
          }
        })
        .catch((error) => {
          handleLogout();
        });
    }
  }, [navigate]);

  useEffect(() => {
    getUserList();
  }, [navigate]);

  const handleUserUpdated = () => {
    console.log("handleUserUpdated");
    getUserList();
  };

  return (
    <Flex flexDirection="column" height="100vh">
      <Flex
        backgroundColor="blue.500"
        justifyContent="space-between"
        alignItems="center"
        padding={4}
        height="80px"
        width="100%"
        zIndex="1000"
      >
        <Text color="white" fontSize="xl">
          益语智库管理系统
        </Text>
        <Flex justifyContent="flex-end" alignItems="center" mr="30px">
          <HStack spacing={4} minW="250px">
            <Menu>
              <>
                <MenuButton
                  as={Button}
                  textAlign="left"
                  bg="white"
                  color="gray.500"
                  p={"12px 18px"}
                  w="100%"
                  h="60px"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box>
                      <Text>Hello,</Text>
                      <Text>{adminInfo?.admin_username}</Text>
                    </Box>
                    <TriangleDownIcon mr="30px" ml="10px" color="gray.500" />
                  </Flex>
                </MenuButton>
                <MenuList>
                  <MenuItem
                    onClick={() => setChangePasswordModalOpen(true)}
                    fontSize="16px"
                    color="gray.600"
                  >
                    修改密码
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    fontSize="16px"
                    color="gray.600"
                  >
                    退出登录
                  </MenuItem>
                </MenuList>
              </>
            </Menu>
            <Avatar size="lg" m="-45px" />
          </HStack>
        </Flex>
      </Flex>
      <Flex flex="1">
        <VStack
          spacing={4}
          align="stretch"
          backgroundColor="gray.200"
          padding={4}
          height="100%"
          w="150px"
        >
          <Link href="/users">用户管理</Link>
        </VStack>
        <Box padding={4} w="100%">
          <UserTable userList={userList} onUserUpdated={handleUserUpdated} />
        </Box>
        <ChangePasswordModal
          isOpen={isChangePasswordModalOpen}
          onClose={() => setChangePasswordModalOpen(false)}
        />
      </Flex>
    </Flex>
  );
};
