import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { User } from "views";
import { EditMenusModal, AddMenusModal } from "components";

interface Menu {
  menu_id: number;
  user_id: number;
  menu_name: string;
  link: string;
  sort: number;
}

interface MenuTableProps {
  user: User | null;
}

export const MenusTable: React.FC<MenuTableProps> = ({ user }) => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const toast = useToast();
  const [menus, setMenus] = useState<Menu[]>([]);

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isMenuEditModalOpen, setMenuEditModalOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuToDelete, setMenuToDelete] = useState<Menu | null>(null);
  const cancelRef = useRef(null);

  const fetchMenus = async () => {
    if (user) {
      try {
        const response = await axios.get(
          `${API_ENDPOINT}/admin/menus?user_id=${user.user_id}`,
          {
            headers: {
              "x-access-admin-token": localStorage.getItem("admin-token"),
            },
          }
        );
        setMenus(response.data);
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    }
  };

  useEffect(() => {
    fetchMenus();
  }, [user]);

  const handleAdd = () => {
    setAddModalOpen(true);
  };

  const handleEdit = (menu: Menu) => {
    setSelectedMenu(menu);
    setMenuEditModalOpen(true);
  };

  const handleDelete = (menu: Menu) => {
    setMenuToDelete(menu);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (menuToDelete) {
      try {
        const response = await axios.delete(
          API_ENDPOINT + `/admin/menus/${menuToDelete.menu_id}`,
          {
            headers: {
              "x-access-admin-token": localStorage.getItem("admin-token"),
            },
          }
        );
        if (response.status === 200) {
          // Refresh the user list here
          fetchMenus();
          setDeleteDialogOpen(false);
          toast({
            title: "删除成功",
            description: "菜单删除成功",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "删除失败",
            description: "菜单删除失败",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        toast({
          title: "删除失败",
          description: "菜单删除失败",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  return (
    <Box w="100%">
      <Text fontWeight="bold" color="gray.600">
        用户信息：
      </Text>
      <Table variant="simple" size="lg" w="100%">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>用户名</Th>
            <Th>邮箱</Th>
            <Th>电话</Th>
            <Th>机构</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr key={user?.user_id}>
            <Td>{user?.user_id}</Td>
            <Td>{user?.username}</Td>
            <Td>{user?.email}</Td>
            <Td>{user?.phone}</Td>
            <Td>{user?.organization_name}</Td>
          </Tr>
        </Tbody>
      </Table>
      <hr />
      <Text fontWeight="bold" color="gray.600" mt="20px">
        菜单管理：
      </Text>
      <Button colorScheme="teal" mb={4} mt="10px" onClick={handleAdd}>
        新增菜单
      </Button>
      <Table variant="simple" size="lg" w="100%">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>菜单名</Th>
            <Th>链接</Th>
            <Th>排序</Th>
            <Th>操作</Th>
          </Tr>
        </Thead>
        <Tbody>
          {menus.map((menu) => (
            <Tr key={menu.menu_id}>
              <Td>{menu.menu_id}</Td>
              <Td>{menu.menu_name}</Td>
              <Td>{menu.link}</Td>
              <Td>{menu.sort}</Td>
              <Td>
                <Button
                  colorScheme="blue"
                  size="sm"
                  mr={2}
                  onClick={() => handleEdit(menu)}
                >
                  编辑
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleDelete(menu)}
                >
                  删除
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {selectedMenu && (
        <EditMenusModal
          isOpen={isMenuEditModalOpen}
          onClose={() => setMenuEditModalOpen(false)}
          menu={selectedMenu}
          onMenuUpdated={fetchMenus}
        />
      )}
      <AddMenusModal
        userId={user?.user_id}
        isOpen={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        onMenuAdded={fetchMenus}
      />
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              删除菜单
            </AlertDialogHeader>
            <AlertDialogBody>确定要删除该菜单吗？</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setDeleteDialogOpen(false)}
              >
                取消
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                删除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
