import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { EditUserModal, AddUserModal } from "components";
import axios from "axios";

interface User {
  user_id: number;
  username: string;
  email: string;
  phone: string;
  organization_name: string;
}

interface UserTableProps {
  userList: User[];
  onUserUpdated: () => void; // Callback to refresh the user list after update
}

export const UserTable: React.FC<UserTableProps> = ({
  userList,
  onUserUpdated,
}) => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const navigate = useNavigate();
  const toast = useToast();
  const [isUserEditModalOpen, setUserEditModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const cancelRef = useRef(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const handleMenu = (user: User) => {
    navigate("/menus/" + user.user_id);
  };

  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setUserEditModalOpen(true);
  };

  const handleDelete = (user: User) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const handleAdd = () => {
    setAddModalOpen(true);
  };

  const handleUserUpdated = () => {
    onUserUpdated();
  };

  const handleUserAdded = () => {
    onUserUpdated();
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      try {
        const response = await axios.delete(
          API_ENDPOINT + `/admin/users/${userToDelete.user_id}`,
          {
            headers: {
              "x-access-admin-token": localStorage.getItem("admin-token"),
            },
          }
        );
        if (response.status === 200) {
          // Refresh the user list here
          handleUserUpdated();
          setDeleteDialogOpen(false);
          toast({
            title: "删除成功",
            description: "用户删除成功",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "删除失败",
            description: "用户删除失败",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        toast({
          title: "删除失败",
          description: "用户删除失败",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  return (
    <Box w="100%">
      <Button colorScheme="teal" mb={4} onClick={handleAdd}>
        新增用户
      </Button>

      <Table variant="simple" size="lg" w="100%">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>用户名</Th>
            <Th>邮箱</Th>
            <Th>电话</Th>
            <Th>机构</Th>
            <Th>操作</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userList.map((user) => (
            <Tr key={user.user_id}>
              <Td>{user.user_id}</Td>
              <Td>{user.username}</Td>
              <Td>{user.email}</Td>
              <Td>{user.phone}</Td>
              <Td>{user.organization_name}</Td>
              <Td>
                <Button
                  colorScheme="teal"
                  size="sm"
                  mr={2}
                  onClick={() => handleMenu(user)}
                >
                  管理菜单
                </Button>
                <Button
                  colorScheme="blue"
                  size="sm"
                  mr={2}
                  onClick={() => handleEdit(user)}
                >
                  编辑
                </Button>
                <Button
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleDelete(user)}
                >
                  删除
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {selectedUser && (
        <EditUserModal
          isOpen={isUserEditModalOpen}
          onClose={() => setUserEditModalOpen(false)}
          user={selectedUser}
          onUserUpdated={handleUserUpdated}
        />
      )}
      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        onUserAdded={handleUserAdded}
      />
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              删除用户
            </AlertDialogHeader>
            <AlertDialogBody>确定要删除此用户吗？</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setDeleteDialogOpen(false)}
              >
                取消
              </Button>
              <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                删除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* Pagination component can be added here */}
    </Box>
  );
};
