import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUserAdded: () => void; // Callback to refresh the user list after adding
}

export const AddUserModal: React.FC<AddUserModalProps> = ({
  isOpen,
  onClose,
  onUserAdded,
}) => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const toast = useToast();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const resetForm = () => {
    setUsername("");
    setEmail("");
    setPhone("");
    setOrganizationName("");
    setPassword("");
    setErrorMessage(null);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleAdd = async () => {
    setErrorMessage("");
    if (!username || !email || !password) {
      setErrorMessage("用户名、邮箱和密码都是必填项。");
      return;
    }

    const body = {
      username,
      email,
      phone,
      organization_name: organizationName,
      password,
    };

    try {
      const response = await axios.post(API_ENDPOINT + `/admin/users`, body, {
        headers: {
          "x-access-admin-token": localStorage.getItem("admin-token"),
        },
      });

      if (response.status === 200) {
        onUserAdded();
        onClose();
        toast({
          title: "新增成功",
          description: "用户新增成功",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "新增失败",
          description: "用户新增失败",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "新增失败",
        description: "用户新增失败",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="gray.600">新增用户</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel color="gray.600">用户名</FormLabel>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">邮箱</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">电话</FormLabel>
            <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">组织名称</FormLabel>
            <Input
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel color="gray.600">密码</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {errorMessage && (
            <Box color="red.500" mb={4}>
              {errorMessage}
            </Box>
          )}
          <Button colorScheme="blue" mr={3} onClick={handleAdd}>
            添加
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            取消
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
