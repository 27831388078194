import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

interface Menu {
  menu_id: number;
  user_id: number;
  menu_name: string;
  link: string;
  sort: number;
}

interface EditMenusModalProps {
  isOpen: boolean;
  onClose: () => void;
  menu: Menu;
  onMenuUpdated: () => void;
}

export const EditMenusModal: React.FC<EditMenusModalProps> = ({
  isOpen,
  onClose,
  menu,
  onMenuUpdated,
}) => {
  const API_ENDPOINT = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const toast = useToast();

  const [menuName, setMenuName] = useState(menu?.menu_name || "");
  const [link, setLink] = useState(menu?.link || "");
  const [sort, setSort] = useState(menu?.sort.toString() || "0");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setMenuName(menu.menu_name);
    setLink(menu.link);
    setSort(menu.sort.toString());
  }, [menu, isOpen]);

  const handleSave = async () => {
    if (!menuName || !link || !sort) {
      setErrorMessage("所有字段都是必填的。");
      return;
    }

    const body = {
      menu_name: menuName,
      link,
      sort: parseInt(sort, 10),
    };

    try {
      const response = await axios.put(
        `${API_ENDPOINT}/admin/menus/${menu?.menu_id}`,
        body,
        {
          headers: {
            "x-access-admin-token": localStorage.getItem("admin-token"),
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "菜单更新成功",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        onMenuUpdated();
        handleClose();
      } else {
        toast({
          title: "菜单更新失败",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      toast({
        title: "菜单更新失败",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleClose = () => {
    // Reset the internal state
    setMenuName("");
    setLink("");
    setSort("");
    setErrorMessage("");

    // Call the passed onClose function
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>编辑菜单</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>菜单名</FormLabel>
            <Input
              value={menuName}
              onChange={(e) => setMenuName(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>链接</FormLabel>
            <Input value={link} onChange={(e) => setLink(e.target.value)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>排序</FormLabel>
            <Input
              type="number"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          {errorMessage && (
            <Box color="red.500" mb={4}>
              {errorMessage}
            </Box>
          )}
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            保存
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            取消
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
