import { Login, Users, Menus } from "views";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Users />} />
      <Route path="/users" element={<Users />} />
      <Route path="/login" element={<Login />} />
      <Route path="/menus/:user_id" element={<Menus />} />
    </Routes>
  </Router>
);
